import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './configs/routes'
import VueMeta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
import ScrollDirective from './directives/scroll.directive'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import axios from 'axios';
import auth from './configs/auth';
import ScrollActive from 'vue-scrollactive';
import { i18n } from './configs/i18n'
import vueCookies from 'vue-cookies'
import VueCarousel from 'vue-carousel';
import VueScrollTo from 'vue-scrollto';

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import store from './store'

VueMarkdownEditor.lang.use('en-US', enUS);
VueMarkdownEditor.use(vuepressTheme);

Vue.config.productionTip = false

if(window.location.href.indexOf("order") > -1 && window.location.href.indexOf("admin") > -1) {
  Vue.prototype.$site_started_from_url = window.location.href;
}

Vue.router = router;
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(VueLazyload)
Vue.use(BootstrapVue);
Vue.use(ScrollActive);
Vue.use(vueCookies);
Vue.use(VueCarousel);
Vue.use(VueAxios, axios);
Vue.use(VueMarkdownEditor);

axios.defaults.baseURL = process.env.BASE_URL + 'backend/api';

//axios.defaults.baseURL = 'http://dnd-dev.esy.es/backend/api';


Vue.use(VueAuth, auth);

Vue.directive('scroll', ScrollDirective);

new Vue({
  store,
  router: router,
  i18n: i18n,
  render: h => h(App)
}).$mount('#dnd')
