export default {
  actions: {
    async fetchCodes({ commit, getters }) {
      commit('setCodesLoading', true);
      
      const res = await this._vm.$http.get(getters.getRoutes.getAll);
 
      commit('setCodesLoading', false);
      commit('setPromoCodes', res.data.data);
    },
    async fectchCode({commit, getters }, id) {
      commit('setCodesLoading', true);
      
      const res = await this._vm.$http.get(getters.getRoutes.getSingle(id));
 
      commit('setCodesLoading', false);
      commit('setPromoCodeItem', res.data.data);
    },
    async fectchDiscountByOrderId({commit, getters}, id) {
      commit('setCodesLoading', true);
      
      const res = await this._vm.$http.get(getters.getRoutes.getDiscountByOrder(id));
 
      commit('setCodesLoading', false);
      commit('setPromoCodeItem', res.data.data);
    },
    async createCode({commit, getters}, model) {
      try {
        commit('setPromoCodeActionError', {});
        commit('setCodesLoading', true);
        const res = await this._vm.$http.post(getters.getRoutes.createCode, model);
        commit('setCodesLoading', false);
        commit('setPromoCodeItem', res.data.data);
      } catch (error) {
        commit('setCodesLoading', false);
        commit('setPromoCodeActionError', error.response.data.errors);
      }
    },
    async updateCode({commit, getters}, model) {
      try {
        commit('setPromoCodeActionError', {});
        commit('setCodesLoading', true);
        const res = await this._vm.$http.put(getters.getRoutes.updateCode, model);
        commit('setCodesLoading', false);
        commit('setPromoCodeItem', res.data.data);
      } catch (error) {
        commit('setCodesLoading', false);
        commit('setPromoCodeActionError', error.response.data.errors);
      }
    },
    async deleteCode({commit, getters}, id) {
      try {
        commit('setPromoCodeActionError', {});
        commit('setCodesLoading', true);
        await this._vm.$http.delete(getters.getRoutes.deleteCode(id));
        commit('setCodesLoading', false);
      } catch (error) {
        commit('setCodesLoading', false);
        commit('setPromoCodeActionError', error.response.data.errors);
      }
    }
  },
  mutations: {
    setCodesLoading(state, isLoading) {
      state.promoCodesLoading = isLoading;
    },
    setPromoCodes(state, arr) {
      state.promoCodes = arr;
    }, 
    setPromoCodeItem(state, item) {
      state.promoCodeItem = item;
    },
    setPromoCodeActionError(state, errors) {
      state.promoCodesErrors = errors;
    }
  },
  state: {
    requstRoutes: {
      getAll: 'discount-code',
      getSingle: (id) => 'discount-code/' + id,
      getDiscountByOrder: (order_id) => 'discount-code/order/' + order_id,
      createCode: 'discount-code',
      updateCode: 'discount-code',
      deleteCode: (id) => 'discount-code/' + id
    },
    promoCodes: [],
    promoCodesLoading: false,
    promoCodeItem: null,

    promoCodesErrors: {}
  },
  getters: {
    getRoutes(state) {
      return state.requstRoutes;
    },
    isPromoCodesLoading(state) {
      return state.promoCodesLoading;
    },
    getPromoCodes(state) {
      return state.promoCodes;
    },
    getPromoCodeItem(state) {
      return state.promoCodeItem;
    },
    getPromoCodeErrors(state) {
      return state.promoCodesErrors;
    },
    isDiscountCodeActionSuccess(state) {
      return Object.keys(state.promoCodesErrors).length === 0
    }
  }
};