
export default {
  actions: {
    async fetchPackages({ commit }) {
      commit('setLoadingPackagesStatus', true);
      const res = await this._vm.$http.get('packages');
      commit('setLoadingPackagesStatus', false);
      commit('setPackagesLists', res.data.data);
    }
  },
  mutations: {
    setLoadingPackagesStatus(state, value) {
      state.loadingPackages = value;
    },
    setPackagesLists(state, packages) {
      state.packages = packages.filter(p => p.type == 0);
      state.special_packages = packages
        .filter(p => p.type == 1)
        .sort((a,b) => (a.views_amount > b.views_amount) ? 1 : ((b.views_amount > a.views_amount) ? -1 : 0));
    }
  },
  state: {
    loadingPackages: false,
    packages: [],
    special_packages: []
  },
  getters: {
    getPackages(state) {
      return state.packages;
    },
    getSpecialPackages(state) {
      return state.special_packages;
    }
  }
};