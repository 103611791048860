export default {
    isLiteral: true,
    inserted: function (el, binding) {
        let f = function (evt) {
            const windowTop = window.pageXOffset === undefined ? document.documentElement.scrollTop : window.pageYOffset;
          
            if (binding.value(evt, el, windowTop)) {
                window.removeEventListener('scroll', f)
            }
        }

        window.addEventListener('scroll', f)
    }
}