import VueRouter from 'vue-router';
const IndexPage = () => import('../pages/Index');
const AboutUsPage = () => import('../pages/AboutUs');
const ServiesPage = () => import('../pages/Services');
const WorksPage = () => import('../pages/Works');
const Contacts = () => import('../pages/ContactUs');
const SenaKana = () => import('../pages/SenaKana');
const PooBear = () => import('../pages/PooBear');
const PrivacyPage = () => import('./../pages/Privacy');
const YouTubePromotionLP = () => import('./../pages/YouTubePromo');
const YouTubePromotionLPJa = () => import('./../pages/YouTubePromoJp');
const YouTubePromotionLPLong = () => import('./../pages/YouTubePromoLong');
const Checkout = () => import('./../pages/Checkout/Checkout');
const CheckoutSpecial = () => import('./../pages/Checkout/CheckoutSpecial');
const CheckoutSuccess = () => import('./../pages/CheckoutSuccess');
const CheckoutError = () => import('./../pages/CheckoutError');
const CheckoutPaymentError = () => import('./../pages/CheckoutPaymentError');

const Register = () => import('../admin/Register');
const Login = () => import('../admin/Login');
const Dashboard = () => import('../admin/Dashboard');
const Admin = () => import('../admin/Admin');
const Users = () => import('../admin/Users/Users');
const User = () => import('../admin/Users/User');
const Settings = () => import('../admin/Settings');
const Photos = () => import('../admin/Photos');
const Email = () => import('../admin/ContactForms/Emails');
const Packages = () => import('./../admin/Packages/Packages');
const Orders = () => import('./../admin/Orders/Orders');
const Order = () => import('./../admin/Orders/Order');
const FAQ = () => import('./../admin/FAQ/FAQ');
const Videos = () => import('./../admin/Video/VideoListPage');
const Customers = () => import('./../admin/Customers/Customers');
const Customer = () => import('./../admin/Customers/Customer');
const MarketingTemplatePreview = () => import('./../admin/MarketingEmailTemplates/TemplatePreview');
const MarketingEvents = () => import('./../admin/MarketingEvents/Events');
const EventCustomers = () => import('./../admin/MarketingEvents/CustomersList');
const EventEditor = () => import('./../admin/MarketingEvents/Event');

const DiscountList = () => import('./../admin/Discount/DiscountList');
const CreateDiscount = () => import('./../admin/Discount/CreateDiscount');
const EditDiscount = () => import('./../admin/Discount/EditDiscount');

const routes = [
    { path: '/x', redirect: '/youtube' },
    {
        path: '/',
        name: 'Home',
        component: IndexPage
    },
    {
        path: '/company',
        name: 'Company',
        component: AboutUsPage
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: PrivacyPage
    },
    {
        path: '/services',
        name: 'Services',
        component: ServiesPage
    },
    {
        path: '/works',
        name: 'Works',
        component: WorksPage
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts
    },
    {
        path: '/sena-kana',
        name: 'sena-kana',
        component: SenaKana
    },
    {
        path: '/poo-bear',
        name: 'poo-bear',
        component: PooBear
    },
    {
        path: '/youtubepromo_lp',
        name: 'YouTube Promotion LP',
        component: YouTubePromotionLP
    },
    {
        path: '/youtubepromo_lp_jp',
        name: 'YouTube Promotion LP',
        component: YouTubePromotionLPJa
    },
    {
        path: '/youtube',
        name: 'YouTube Promo LP Long',
        component: YouTubePromotionLPLong
    },
    {
        path: "/checkout",
        name: "checkout",
        component: Checkout 
    },
    {
        path: "/checkout-special",
        name: "checkout-special",
        component: CheckoutSpecial 
    },
    {
        path: "/payment-success",
        name: "checkout-success",
        component: CheckoutSuccess 
    },
    {
        path: "/checkout-error",
        name: "checkout-error",
        component: CheckoutError
    },
    {
        path: "/checkout-payment-error",
        name: 'checkout-payment-error',
        component: CheckoutPaymentError
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            auth: false
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            auth: true
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        },
    },
    {
        path: '/admin/users',
        name: 'users',
        component: Users,
        meta: {
            auth: { roles: 2, redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/user/:id',
        name: 'user',
        component: User,
        meta: {
            auth: { roles: 2, redirect: { name: 'login' }, forbiddenRedirect: '/'}
        }
    },
    {
        path: '/admin/settings',
        name: 'settings',
        component: Settings,
        meta: {
             auth: { roles: 2, redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/photos',
        name: 'photos',
        component: Photos,
        meta: {
            auth: { roles: [ 2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/emails',
        name: 'Emails',
        component: Email,
        meta: {
            auth: { roles: 2, redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/packages',
        name: 'Packages',
        component: Packages,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/faq',
        name: 'FAQ',
        component: FAQ,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/orders',
        name: 'Orders',
        component: Orders,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/order/:order_id',
        name: 'Order',
        component: Order,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/videos',
        name: 'Videos',
        component: Videos,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/customers',
        name: 'Customers',
        component: Customers,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/customer/:id',
        name: 'Customer',
        component: Customer,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/marketing/events',
        name: 'MarketingEvents',
        component: MarketingEvents,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/marketing/events/customers/:event_id',
        name: 'EventCustomers',
        component: EventCustomers,
        meta: {
           auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/marketing/event/:event_id',
        name: 'EventEditor',
        component: EventEditor,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/marketing/event/template/:event_id',
        name: 'MarketingTemplatePreview',
        component: MarketingTemplatePreview,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/discount',
        name: 'DiscountList',
        component: DiscountList,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/discount/create',
        name: 'CreateDiscount',
        component: CreateDiscount,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    },
    {
        path: '/admin/discount/edit/:code_id',
        name: 'EditDiscount',
        component: EditDiscount,
        meta: {
            auth: { roles: [2, 3], redirect: { name: 'login' }, forbiddenRedirect: '/' }
        }
    }
];

const router = new VueRouter({
    history: true,
    scrollBehavior (to) {
        if (to.hash) {
            return {selector: to.hash}
        } else {
            return { x: 0, y: 0 }
        }
    },
    mode: 'history',
    routes
});

export default router
