import Vue from 'vue';
import Vuex from 'vuex';
import customerFilter from './admin/customers-filter';
import customersList from './admin/customers-list';
import event from './admin/event';
import discount from './admin/discount';
import packages from './admin/packages';

import checkoutPackage from './checkout/checkoutPackage';
import checkoutDiscount from './checkout/checkoutDiscount';

import brouser from './brouser';

Vue.use(Vuex);

export default  new Vuex.Store({
    modules: {
        customerFilter,
        customersList,
        event,
        discount,
        packages,

        // checkout
        checkoutPackage,
        checkoutDiscount,
        
        // common
        brouser
    }
});