export default {
  actions: {
    async fetchCheckoutPackage({ commit }, slug) {
      const res = await this._vm.$http.get('packages/slug/' + slug);
      commit('setCheckoutPackage', res.data.data);
      commit('clearCheckoutPromoCodeValidationResult');
    },
    async fetchCheckoutPackageOrTakeFetched({ commit, getters, actions }, slug) {
      var item = getters.getCheckoutSpecialPackages.filter(p => p.slug === slug)[0];

      commit('clearCheckoutPromoCodeValidationResult');
      
      if(item) {
        commit('setCheckoutPackage', item);
        commit('clearCheckoutPromoCodeValidationResult');
      } else {
        await actions.fetchCheckoutPackage(slug);
      }
    },
    async fetchCheckoutSpecialPackages({ commit }) {
      const res = await this._vm.$http.get('packages/special');
      commit('setCheckoutSpecialPackages', res.data.data);
      commit('setCheckoutPackage', res.data.data[0]);
    }
  },
  mutations: {
    setCheckoutPackage(state, {
      id,
      slug,
      title,
      description,
      description_ja,
      weeks_count_min,
      weeks_count_max,
      has_promo_codes,
      discount_value,
      discount,
      price,
      views_amount
    }) {
      state.checkoutPackage = {
        id,
        slug,
        title,
        description,
        description_ja,
        weeks_count_min,
        weeks_count_max,
        price,
        views_amount,
        viewsAmountLocal: parseInt(views_amount).toLocaleString('ru-RU'),
        has_promo_codes,
        discount: Number(discount_value),
        discount_value: Number(discount),
      };
    },
    setCheckoutSpecialPackages(state, value) {
      state.specialPackages = value;
    }
  },
  state: {
    checkoutPackage: null,
    specialPackages: [],
  },
  getters: {
    getCheckoutPackage({ checkoutPackage }) {
      return checkoutPackage;
    },
    getCheckoutSpecialPackages({ specialPackages }) {
      return specialPackages;
    },
    getSpecialSliderOptions({ specialPackages }) {

      if(specialPackages && !specialPackages.length)
        return null;

      const sortResp = specialPackages.sort((a, b) => a.views_amount > b.views_amount ? 1 : -1);

      let lastViewsAmount = sortResp[sortResp.length - 1].views_amount - (sortResp[sortResp.length - 1].views_amount % 1000);

      let firstVal = sortResp[0].views_amount - (sortResp[0].views_amount % 1000);

      let middleValue = (lastViewsAmount - sortResp[0].views_amount) / 2;

      const divDiff = sortResp.length % 2;
      
      if(divDiff == 0)
      {
          const firstVal = sortResp[sortResp.length / 2 - 1].views_amount;
          const secondVal = sortResp[sortResp.length / 2].views_amount;
          middleValue = firstVal + (secondVal -  firstVal) / 2;
      }
      else 
      {
          middleValue = sortResp[(sortResp.length - divDiff) / 2 + 1].views_amount;
      }

      middleValue = Math.ceil(middleValue / 1000) * 1000

      middleValue = middleValue - (middleValue % 1000);

      return {
        viewsAmounts: [firstVal, middleValue, lastViewsAmount],
        options: specialPackages.map(p => p.slug)
      };
    }
  }
};