
export default {
    actions: { 
        async sendCompaing({commit, getters}) {
            
            commit('setIsEventSending', true);
            const customers = getters.getEventCustomers;
            
            for(let i = 0; i < customers.length; i++) {
                await this._vm.$http.get('marketing-events/send/'+ getters.getEvent.id + '/customer/' + customers[i].id);
            }

            commit('setIsEventSending', false);
        },
        async getEventCustomersNewList({commit, getters}) {
            commit('eventCustomersListLoading', true);
            
            const res = await this._vm.$http.post('customers/event', { 
                filter: getters.getForQuery 
            });
            commit('clearEvent');
            commit('updateEventCustomersList', res.data.customers);
            
            commit('eventCustomersListLoading', false);
        },
        async createEventAction({commit, getters }) {
            commit('eventCustomersListLoading', true);

            const res = await this._vm.$http.post('customers/event/create', { 
                customerIds: getters.getCustomersToCtreateEvent 
            });

            commit('updateEvent', res.data.event);
            commit('eventCustomersListLoading', false);
        },
        async updatedEventAction({commit, getters}) {
            commit('eventCustomersListLoading', true);

            const res = await this._vm.$http.post('customers/event/update', { 
                event_id: getters.getEvent.id,
                customerIds: getters.getCustomersToCtreateEvent 
            });

            commit('updateEvent', res.data.event);
            commit('eventCustomersListLoading', false);
        },
        async getEventAction({ commit }, eventId) {
            commit('eventCustomersListLoading', true);

            const res = await this._vm.$http.get('marketing-event/' + eventId);

            commit('updateEvent', res.data.event);
            commit('eventCustomersListLoading', false);
        },
        async getEventsAction({ commit }) {
            commit('eventCustomersListLoading', true);

            const res = await this._vm.$http.get('marketing-events');

            commit('updateEvents', res.data.events);
            commit('eventCustomersListLoading', false);
        },

        async saveTemplateAction({commit, getters}) {
            
            commit('eventCustomersListLoading', true);
            const data = getters.getTemplate;
            data.event_id = getters.getEvent.id;

            const res = await this._vm.$http.post('marketing-email', data);

            commit('updateEvent', res.data.event);
            commit('eventCustomersListLoading', false);
        },
        async saveTemplateWithImageAction({commit, getters}) {
            commit('eventCustomersListLoading', true);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            const res = await this._vm.$http.post('marketing-email', getters.eventTemplateFormDataGetter, config);

            commit('updateEvent', res.data.event);

            commit('eventCustomersListLoading', false);
        },
        async saveTemplateHtmlAction({commit, getters}) {
            commit('eventCustomersListLoading', true);
            const data = getters.getTemplate;
            data.event_id = getters.getEvent.id;

            const res = await this._vm.$http.post('marketing-email', data);

            commit('updateEvent', res.data.event);
            commit('eventCustomersListLoading', false);
        },
        async getTemplatesAction({commit}) {
            commit('eventCustomersListLoading', true);

            const res = await this._vm.$http.get('marketing-emails');

            commit('updateEventTemplates', res.data.templates);
            commit('eventCustomersListLoading', false);
        }
        
    },
    mutations: {
        updateEventTemplates(state, templates) {
            state.templates = templates;
        },
        updateTemplateByParent(state, parentId) {
            const parentTemplate = state.templates.filter(x => x.id == parentId)[0];
            for(const key in parentTemplate) {
                if(key != 'id') {
                    state.template[key] = parentTemplate[key];
                }
            }
        },
        updateTemplate(state, { field, value }) {
            state.template[field] = value;
        },
        updateEventCustomersList(state, customers) {
            state.eventCustomersList = customers;
        },
        groupEventCustomersCheckedUpdate(state, value) {
            const customers = state.eventCustomersList;

            state.eventCustomersList = [];

            for(let i = 0; i < customers.length; i++) {
                customers[i].checked = value;
            }
            
            state.eventCustomersList = customers;
        },
        eventCustomersListLoading(state, isLoading) {
            state.loading = isLoading;
        },
        removeCustomerFromEventList(state, customer) {
            state.eventCustomersList.splice(state.eventCustomersList.indexOf(customer), 1);
        },
        clearEvent(state) {
            state.event = null;
            state.template = {
                'parent_template_id': null,
                'type': 'text',
                'subject': '',
                'sub_title': '',
                'markup': '',
                'title': '',
                'text': '',
                'text2': '',
                'text3': '',
                'action_link': '',
                'action_text': '',
                'image': '',
                'is_has_warning': ''
            };
            state.templateFile = null;
        },
        updateEvent(state, event) {
            state.eventCustomersList = event.customers;
            state.event = event;

            if(event.template) {
                state.template = event.template
            }
        },
        updateEvents(state, events) {
            state.events = events;
        },
        updateTemplateImage(state, imageFile) {
            state.templateFile = imageFile;
        },
        touchTemplateEditor(state, val = true) {
            state.templateEditorTouched = val;
        },
        setIsEventSending(state, val) {
            state.isEventSending = val;
        }
    },
    state: {
        isEventSending: false,
        templates: [],
        templateEditorTouched: false,
        eventCustomersList: [],
        loading: false,
        events: [],
        event: null,
        template: {
            'parent_template_id': null,
            'type': 'text',
            'subject': '',
            'sub_title': '',
            'markup': '',
            'title': '',
            'text': '',
            'text2': '',
            'text3': '',
            'action_link': '',
            'action_text': '',
            'image': ''
        },
        eventTypeOptions: [
            { text: "Text", value: 'text'},
            { text: "Text + Image", value: 'text-image'},
            { text: "HTML", value: 'html'}
        ],
        fieldsToInsert: {
            'first_name': 'John',
            'last_name': 'Doe',
            'email': 'JohnDoe@gmail.com',
            'phone': "+370123003303",
            'country': "Canada",
            'youtubeVideoLink': "https://www.youtube.com/watch?v=u1xrNaTO1bI",
            'youtubeVideoTitle': "Depeche Mode - Personal Jesus (Official Video)",
            'currentViews': '137920518',
            'promotionDoneViews': '10021',
            'package': 'Minimum',
            'packagePrice': '110',
            'packageViews': '10000'
        },
        templateFile: null,
    },
    getters: {
        isEventSending(state) {
            return state.isEventSending;
        },
        getAllTemplates(state) {
            return state.templates;
        },
        getTemplate(state) {
            return state.template;
        },
        getEvents(state) {
            return state.events;
        },
        getCustomersToCtreateEvent(state) {
            return state.eventCustomersList.filter(x => x.checked === undefined || x.checked).map(x => x.id);
        },
        getEventCustomers(state) {
            for(let i = 0; i < state.eventCustomersList.length; i++) {
                
                if(state.eventCustomersList[i].checked === undefined) {
                    state.eventCustomersList[i].checked = true;
                }

                if(!state.eventCustomersList[i].is_valid) {
                    state.eventCustomersList[i]._rowVariant = 'danger';
                }
            }

            return state.eventCustomersList;
        },
        getEventCustomersTotal(state) {
            return state.eventCustomersList.length;
        },
        isEventCustomersLoading(state) {
            return state.loading;
        },
        getEventTypeOptions(state) {
            return state.eventTypeOptions;
        },
        getFieldsToInsertWithMocData(state) {
            return state.fieldsToInsert;
        },
        getFieldsToInsert(state) {
            const fields = [];

            for(const key in state.fieldsToInsert) {
                fields.push(key);
            }

            return fields;
        },
        getToolbar(state, getters) {
            const fieldsSource = getters.getFieldsToInsert;
            const fields = [];

            for(let i = 0; i < fieldsSource.length; i++) {
                fields.push({
                    name: fieldsSource[i],
                    text: fieldsSource[i],
                    action(editor) {
                        editor.insert(() => {
                            return {
                                text: `{{${fieldsSource[i]}}}`
                            };
                        });
                    }
                })
            }

            return {
                customer_fields: {
                    title: 'Customer',
                    icon: 'v-md-icon-tip',
                    menus: fields
                },
            };
        },
        isEventExists(state) {
            return state.event !== null;
        },
        getEvent(state) {
            return state.event;
        },
        eventTemplateFormDataGetter(state) {
            const form = new FormData();
            form.append('type', state.template.type);
            form.append('subject', state.template.subject);
            form.append('title', state.template.title);
            form.append('sub_title', state.template.subject);
            form.append('text', state.template.text);
            form.append('text2', state.template.text2);
            form.append('text3', state.template.text3);
            form.append('action_link', state.template.action_link);
            form.append('action_text', state.template.action_text);
            form.append('is_has_warning', state.template.is_has_warning);
            form.append('image', state.template.image);
            form.append('file', state.templateFile);
            form.append('event_id', state.event.id);
            return form;
        },
        eventTemplateFileGetter(state) {
            return state.templateFile;
        },
        eventTemplateValidInfoGetter(state) {

            if(state.template.type == 'html') {
                return {
                    subject: !!state.template.subject,
                    markup: !!state.template.markup
                };
            }

            if(state.template.type == 'text') {
                return {
                    subject: !!state.template.subject,
                    title: !!state.template.title,
                    text: !!state.template.text
                };
            }

            if(state.template.type == 'text-image') {
                return {
                    subject: !!state.template.subject,
                    title: !!state.template.title,
                    text: !!state.template.text,
                    image: !!state.template.image || !!state.templateFile,
                    text2: !!state.template.text2
                };
            }
        },
        eventTemplateValidGetter(state, getters) {
            const validRes = getters.eventTemplateValidInfoGetter;
            
            for(const key in validRes) {
                if(!validRes[key]) {
                    return false;
                }
            }

            return true;
        },
        isToucedAllTemplateEditor(state) {
            return state.templateEditorTouched;
        }
    }
};
