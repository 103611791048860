export default {
  state: {
    isSafari: false,
  },
  getters: {
    isIos() {
      return (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream;
    }
  }
};