
const getFields = () => [
    {
        title: 'Email',
        key: 'email',
        hidden: false,
    },
    {
        title: 'Full Name',
        key: 'full_name',
        hidden: false,
    },
    {
        title: 'Country',
        key: 'country',
        hidden: false,
    },
    {
        title: 'Paid Amount',
        key: 'total_spent_money',
        hidden: false,
    },
    {
        title: 'Last order date',
        key: 'last_order_date',
        hidden: false,
    }, 
    {
        title: 'Last order status',
        key: 'last_order_type',
        hidden: false,
    },
    {
        title: 'Last marketing event date',
        key: 'last_marketing_event_date',
        hidden: false,
    }
];

const getDefaultCreteria = function (group) {
    return {
        fields: getFields(),
        fieldsDisabled: false,
        field: 'email', 
        value: '',
        group: group
    }
};

const disableSelectedCreterias = function (state) {
    if(state.creterias.length == 0)
        return;

    for(let i = 0; i < state.creterias.length; i++) {
        state.creterias[i].fieldsDisabled = true;
    }
    
    state.creterias[state.creterias.length - 1].fieldsDisabled = false;
};

const disableFieldsPerGroup = function(state, fieldName) {
    let group = null;

    for(let i = 0; i < state.creterias.length; i++) {
        if(state.creterias[i].field == fieldName) {
            group = state.creterias[i].group;
            break;
        }
    }

    for(let i = 0; i < state.creterias.length; i++) {
        if(group !== null && state.creterias[i].group != group) {
            for(let j = 0; j < state.creterias[i].fields.length; j++) {
                if(state.creterias[i].fields[j].key == fieldName) {
                    state.creterias[i].fields[j].hidden = true;
                }   
            }
        } else {
            for(let j = 0; j < state.creterias[i].fields.length; j++) {
                if(state.creterias[i].fields[j].key == fieldName) {
                    state.creterias[i].fields[j].hidden = false;
                }   
            }
        }
    }
};

const checkIsValidFilter = function(state) {
    for(let i = 0; i < state.creterias.length; i++) {
        if(Array.isArray(state.creterias[i].value) && !state.creterias[i].value[0] && !state.creterias[i].value[1]) {
            return false;
        } else if(!state.creterias[i].value) {
            return false;
        }
    }
    return true;
};


export default {
    mutations: {
        addCriteria(state, groupIndex) {
            state.isValid = checkIsValidFilter(state);
            state.toched = true;

            if(state.isValid) {
                state.toched = false;
                state.creterias.push(getDefaultCreteria(groupIndex));
                disableSelectedCreterias(state);
                disableFieldsPerGroup(state, 'country');
                disableFieldsPerGroup(state, 'last_order_type');
            }
        },
        createGroup(state) {
            state.isValid = checkIsValidFilter(state);
            state.toched = true;

            if(state.isValid) {
                state.toched = false;
                const latIndex = state.groups.length - 1;
                const groupIndex = latIndex == -1 ? 1 : state.groups[state.groups.length - 1] + 1;
                state.groups.push(groupIndex);
                state.creterias.push(getDefaultCreteria(groupIndex));
                disableSelectedCreterias(state);
                disableFieldsPerGroup(state, 'country');
                disableFieldsPerGroup(state, 'last_order_type');
            }
        },
        deleteCreteria(state, creteria) {
            const creteriaIndex = state.creterias.indexOf(creteria);
            const creteriaGroup = state.creterias[creteriaIndex].group;
            state.creterias.splice(creteriaIndex, 1);
            disableSelectedCreterias(state);
            disableFieldsPerGroup(state, 'country');
            disableFieldsPerGroup(state, 'last_order_type');
            
            if(! state.creterias.some((item) => item.group == creteriaGroup)) {
                state.groups.splice(state.groups.indexOf(creteriaGroup), 1);
            }
        },
        changeCreteriaType(state, change) {
            state.toched = false;
            state.creterias[change.index].value = null;
            state.creterias[change.index].field = change.value;
        }, 
        setRuleValue(state, input) {
            state.creterias[input.index].value = input.value;
        },
        touch(state) {
            state.toched = false;
        }
    },
    state: {
        groups: [],
        creterias: [],
        isValid: true,
        toched: false
    },
    getters: {
        checkIsValid(state) {
            return checkIsValidFilter(state);
        },
        getGroups(state) {
            return state.groups;
        },
        getCreterias(state) {
            return state.creterias;
        },
        getToched(state) {
            return state.toched;
        },
        getForQuery(state) {
            let rules = [];

            for(let i = 0; i < state.groups.length; i++) {
                const groupRules = state.creterias.filter(({group}) => state.groups[i] == group);
                
                const mappedRules = groupRules.map(function({field, value}) { 
                    return {
                        field,
                        value
                    }
                });
                
                rules.push(mappedRules);
            }

            return rules;
        }
    }
}