
export default {
    actions: {
        async fetchCustomers({ commit, getters }) {
            const skip = getters.getPerPage * getters.getCurrentPage - getters.getPerPage;
            const url = 'customers/' + getters.getPerPage + "/skip/" + skip + "/sort/" + getters.getSortField + "/dir/" + getters.getSortDirection;
            
            commit('updateLoading', true);
            
            const res = await this._vm.$http.post(url, { filter: getters.getForQuery });
       
            commit('updateLoading', false);
            commit('updateCustomers', res.data.customers);
            commit('updateCustomersTotal', res.data.total);
        }
    },
    mutations: {
        updateCustomers(state, customers) {
            state.customers = customers;
        },
        updateCustomersTotal(state, customersTotal) {
            state.customersTotal = customersTotal;
        },
        updateCurrentPage(state, currentPage) {
            state.page = currentPage;
        },
        updateItemsPerPage(state, perPage) {
            state.perPage = perPage;
        },
        updateSortField(state, sortField) {
            state.sortField = sortField;
        },
        updateSortDir(state, sortDir) {
            state.sortDir = sortDir;
        },
        updateLoading(state, isLoading) {
            state.loading = isLoading;
        }
    },
    state: {
        customers:[],
        customersTotal: 0,
        page: 1,
        perPage: 10,
        sortField: 'last_activity_date',
        sortDir: 'desc',
        loading: false
    },
    getters: {
        getPerPage(state) {
            return state.perPage;
        },
        getCurrentPage(state) {
            return state.page;
        },
        getCustomers(state) {
            return state.customers;
        },
        getCustomersTotal(state) {
            return state.customersTotal;
        },
        getSortField(state) {
            return state.sortField;
        },
        getSortDirection(state) {
            return state.sortDir;
        },
        isLoading(state) {
            return state.loading;
        }
    }
};