export default {
  actions: {
    async fetchCheckoutPromoCodeValidationResult({ commit, getters }, code) {
      const res = await this._vm.$http.get('packages/' + getters.getCheckoutPackage.id + '/code/' + code);
      commit('setCheckoutPromoCodeValidationResult', res.data.data);
    }
  },
  mutations: {
    setCheckoutPromoCodeValidationResult(state, { 
      is_valid,
      new_price,
      discount_value,
      is_code_invalid,
      is_code_paused,
      is_code_not_started,
      is_code_expiered,
      promo_code
    }) {
      state.checkoutPromoCodeValidResult = {
        is_valid : is_valid,
        new_price: new_price,
        discount_value: discount_value,
        is_code_invalid: is_code_invalid,
        is_code_paused: is_code_paused,
        is_code_not_started: is_code_not_started,
        is_code_expiered: is_code_expiered,
        promo_code: promo_code
      };
    },
    clearCheckoutPromoCodeValidationResult(state) {
      state.checkoutPromoCodeValidResult = null;
    }
  },
  state: {
    checkoutPromoCodeValidResult: null,
  },
  getters: {
    getCheckoutPromoCodeValidationResult(state) {
      return state.checkoutPromoCodeValidResult;
    },
    hasDiscount(state, getters) {

      const promoCode = getters.getCheckoutPromoCodeValidationResult;
      
      if(promoCode && promoCode.is_valid) {
          return true;
      }

      const pak = getters.getCheckoutPackage;

      if(pak.discount) {
          return true;
      }

      return false;
  },
  discountValue(state, getters) {
    const promoCode = getters.getCheckoutPromoCodeValidationResult;

    if(promoCode && promoCode.is_valid) {
      return promoCode.discount_value;
    }

    const pak = getters.getCheckoutPackage;

    if(pak.discount) {
      return pak.discount_value;
    }
    return 0;
  },
  discountPrice(state, getters) {

      const promoCode = getters.getCheckoutPromoCodeValidationResult;

      if(promoCode && promoCode.is_valid) {
          return promoCode.new_price;
      }

      const pak = getters.getCheckoutPackage;

      if(pak.discount) {
          return pak.discount;
      }

      return 0;
    }
  }
};