export const fbChat = {
    methods: {
        hideChat() {
            if(window.FB) {
                window.FB.CustomerChat.hide();
            }      
        },
        showChat() {
            if(window.FB) {
                window.FB.CustomerChat.show(false);
            }
        }
    },
};