<template>
    <div class="curtain" :class="{active : isActive}">
      <img src="/images/logo/D&D_sign_white.png" alt="logo">
    </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$root.$on("curtain_run", (isRun) => {
      this.isActive = !!isRun;
    });
  }
}
</script>
<style lang="scss" scoped>
@import './../scss/app';

.curtain {
  z-index: 9999;
  position: fixed;
  top:100%;
  left: 0;
  right: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  overflow: hidden;
  
  $pulse: 1s;    
  img {
    width:55px;
    height: 55px;
  }
}


$speed: 2.7s;
.curtain.active {
  height: auto;
  @include transition(page-curtain $speed);
  @include animation(page-curtain $speed);
  
  z-index: 9999 !important;
}

@include keyframes(page-curtain) {
  0% {
    top:100%;
    bottom: 0;
  }
  20% {
    top:0;
    bottom: 0;
  }
  80% {
    top:0;
    bottom: 0;
  }
  100% {
    top:-100%;
    bottom: 100%;
  }
}
</style>