<template>
  <div id="app">
    <loader :isActive="isLoading"/>
    <transition name="change-page-animation">
      <router-view></router-view>
    </transition>
    <curtain :isActive="isCurtainActive"/>
  </div>
</template>

<script>
import curtain from './components/curtain';
import loader from './components/loader';
import { fbChat } from './mixins/fb-chat.mixin';
export default {
  data() {
    return {
      isCurtainActive: false,
      isLoading: true && !window.location.href.includes('youtubepromo_lp') && !window.location.href.includes('youtube-long')
    }
  },
  mixins: [fbChat],
  name: 'dnd',
  metaInfo: {
    title: 'D&D'
  },
  components: { 
    curtain,
    loader
  },
  methods: {
    isNotShowFbChat() {
      const isSpeshalPage = window.location.href.indexOf("admin") > -1 || window.location.href.indexOf("checkout") > -1;

      return isSpeshalPage;
    }
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      
      if(window.location.href.indexOf("admin") > -1) {
        next();
        return;
      }

      this.isCurtainActive = true;
      this.hideChat();
      
      return setTimeout(() => {
        next();
        setTimeout(() => {
          this.isCurtainActive = false; 
 
          if(this.isNotShowFbChat()) {
            this.hideChat();
          } else {
            this.showChat();
          }
          
        }, 1800);
        
      }, 900);
    });

    if(this.isLoading) {
      setTimeout(() => {
        this.isLoading = false
        if(this.isNotShowFbChat()) {
            this.hideChat();
        }  
      }, 2700);
    }
  }
}
</script>

<style lang="scss">
@import './scss/app';

.wrrapper {
  position: relative;
}

.wrrapper::after {
  content: "";
  display: block;
  border: solid 0;
  border-radius: 39px;
  border-width: 0;
  position: relative;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  @include transition(600ms ease);
}

.change-page-animation {
  &-enter {
    &.wrrapper::after {
      border-width: 100px;
      z-index: 0;
    }
  }

  &-enter-active {
    .wrrapper {
      height: 100vh;
      overflow: hidden;
    }

    &.wrrapper::after {
      border-width: 100px;
    }
  }

  &-leave-active {
    .wrrapper {
      height: 100vh;
      overflow: hidden;
    }
  }
}
</style>


